.Toastify__toast--success .Toastify__toast-icon,
.Toastify__toast--warning .Toastify__toast-icon,
.Toastify__toast--error .Toastify__toast-icon {
  display: none;
}
.Toastify__toast-icon {
  position: absolute;
  left: 19px;
}
.Toastify__toast {
  min-height: 48px;
  background-color: #fff;
  padding: 8px 16px 8px 56px;
  border-radius: 6px;
  box-shadow: 0px 10px 21px 0px rgba(180, 180, 180, 0.5);
  display: flex;
}
.Toastify__toast::after {
  content: '';
  width: 4px;
  height: calc(100% - 8px);
  background-color: #ccc;
  border-radius: 6px;
  position: absolute;
  left: 4px;
  top: 4px;
}
.Toastify__toast-body {
  font-weight: 700;
  font-size: 13px;
  color: #262626;
  padding: 0;
}
.Toastify__toast::before {
  position: absolute;
  left: 18px;
  width: 20px;
  height: 20px;
  top: 13px;
  display: block;
}
.Toastify__toast--success::before {
  content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM8.82153 11.1125L12.7312 6.72526C12.9019 6.53523 13.1329 6.42857 13.3737 6.42857C13.6144 6.42857 13.8454 6.53523 14.0161 6.72526C14.1016 6.82011 14.1694 6.93296 14.2156 7.05729C14.2619 7.18162 14.2857 7.31498 14.2857 7.44967C14.2857 7.58436 14.2619 7.71772 14.2156 7.84205C14.1694 7.96638 14.1016 8.07923 14.0161 8.17408L9.45946 13.2755C9.28972 13.464 9.06061 13.5703 8.82153 13.5714C8.70159 13.5722 8.58269 13.5465 8.47166 13.4957C8.36062 13.4449 8.25963 13.3701 8.17448 13.2755L6.03284 10.8676C5.93744 10.7762 5.85996 10.6636 5.80525 10.537C5.75055 10.4105 5.71981 10.2726 5.71496 10.1321C5.71012 9.99155 5.73127 9.85144 5.77708 9.72051C5.8229 9.58958 5.8924 9.47066 5.98121 9.37123C6.07002 9.2718 6.17624 9.194 6.29319 9.1427C6.41013 9.09141 6.53529 9.06773 6.6608 9.07316C6.78631 9.07859 6.90946 9.113 7.02252 9.17424C7.13559 9.23548 7.23612 9.32223 7.31782 9.42903L8.82153 11.1125Z' fill='%2340DD19'/%3E%3C/svg%3E%0A");
}
.Toastify__toast--error::before {
  content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM13.7 12.3C14.1 12.7 14.1 13.3 13.7 13.7C13.3 14.1 12.7 14.1 12.3 13.7L10 11.4L7.7 13.7C7.3 14.1 6.7 14.1 6.3 13.7C5.9 13.3 5.9 12.7 6.3 12.3L8.6 10L6.3 7.7C5.9 7.3 5.9 6.7 6.3 6.3C6.7 5.9 7.3 5.9 7.7 6.3L10 8.6L12.3 6.3C12.7 5.9 13.3 5.9 13.7 6.3C14.1 6.7 14.1 7.3 13.7 7.7L11.4 10L13.7 12.3Z' fill='%23E52525'/%3E%3C/svg%3E%0A");
}
.Toastify__toast--warning::before {
  content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM8.82153 11.1125L12.7312 6.72526C12.9019 6.53523 13.1329 6.42857 13.3737 6.42857C13.6144 6.42857 13.8454 6.53523 14.0161 6.72526C14.1016 6.82011 14.1694 6.93296 14.2156 7.05729C14.2619 7.18162 14.2857 7.31498 14.2857 7.44967C14.2857 7.58436 14.2619 7.71772 14.2156 7.84205C14.1694 7.96638 14.1016 8.07923 14.0161 8.17408L9.45946 13.2755C9.28972 13.464 9.06061 13.5703 8.82153 13.5714C8.70159 13.5722 8.58269 13.5465 8.47166 13.4957C8.36062 13.4449 8.25963 13.3701 8.17448 13.2755L6.03284 10.8676C5.93744 10.7762 5.85996 10.6636 5.80525 10.537C5.75055 10.4105 5.71981 10.2726 5.71496 10.1321C5.71012 9.99155 5.73127 9.85144 5.77708 9.72051C5.8229 9.58958 5.8924 9.47066 5.98121 9.37123C6.07002 9.2718 6.17624 9.194 6.29319 9.1427C6.41013 9.09141 6.53529 9.06773 6.6608 9.07316C6.78631 9.07859 6.90946 9.113 7.02252 9.17424C7.13559 9.23548 7.23612 9.32223 7.31782 9.42903L8.82153 11.1125Z' fill='%23FF592B'/%3E%3C/svg%3E%0A");
}
.Toastify__toast--success::after {
  background-color: #40dd19;
}
.Toastify__toast--error::after {
  background-color: #e52525;
}
.Toastify__toast--warning::after {
  background-color: #ff592b;
}
.Toastify__close-button {
  margin: 9.5px 0 0;
}
.Toastify__close-button svg {
  fill: #262626;
  height: 12px;
  width: 12px;
}
