.rich-text-editor ul {
  padding-left: theme(spacing.4);
  list-style: disc;
}

.rich-text-editor ul li {
  padding-bottom: 8px;
}

.rich-text-editor ul li:last-child {
  padding-bottom: 0;
}

.rich-text-editor ul li::marker {
  color: theme(colors.primary.500);
}

.public-DraftEditorPlaceholder-inner {
  position: absolute;
  left: theme(spacing.6);
  top: theme(spacing.10);
  color: theme(colors.gray.400);
}

.public-DraftEditor-content {
  overflow-wrap: anywhere !important;
}
