@import url('./fonts/GalanoGrotesque/GalanoGrotesque.css');
@import url('./fonts/DmSans/DMSans.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./toast.css');
@import url('./animations.css');

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

@layer utilities {
  .pause {
    animation-play-state: paused;
  }
}

html,
body {
  font-family: Galano Grotesque;
}

.grid-area-jobPositionSelect {
  grid-template-areas:
    '. . .'
    'mainTech mainTech mainTech';
}

.grid-area-mainTech {
  grid-area: mainTech;
}

.link-primary {
  @apply text-primary-500 hover:text-primary-600 hover:underline transition-all;
}

.link-secondary {
  @apply text-gray-400 hover:text-gray-500 hover:underline transition-all;
}

.job-board-container {
  @apply px-4 md:max-w-container-rem md:px-[3rem] box-content;
}

.job-board {
  -webkit-font-smoothing: antialiased;
  font-size: calc(0.625rem + 0.41666666666666663vw);
}

@media screen and (max-width: 1920px) {
  .job-board {
    font-size: calc(0.625rem + 0.41666666666666674vw);
  }
}

@media screen and (max-width: 1440px) {
  .job-board {
    font-size: calc(0.5982142857142858rem + 0.4464285714285714vw);
  }
}

@media screen and (max-width: 991px) {
  .job-board {
    font-size: calc(0.7990275526742301rem + 0.3241491085899514vw);
  }
}

@media screen and (max-width: 375px) {
  .job-board {
    font-size: calc(0.3055555555555556rem + 2.962962962962963vw);
  }
}

@media screen and (max-width: 240px) {
  .job-board {
    font-size: calc(0.49789915966386555rem + 1.680672268907563vw);
  }
}
