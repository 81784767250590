.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: theme(colors.primary.500) !important;
}

.react-datepicker__month-text {
  padding: theme(spacing.2) 0;
}

.react-datepicker__navigation {
  top: theme(spacing.[1.5]) !important;
}
