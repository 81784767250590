@font-face {
  font-family: DM Sans;
  src: url('./DMSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: DM Sans;
  font-weight: 500;
  src: url('./DMSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: DM Sans;
  font-weight: 600;
  src: url('./DMSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: DM Sans;
  font-weight: 700;
  src: url('./DMSans-Bold.ttf') format('truetype');
}
