.rc-slider-mark {
  top: theme(spacing.7) !important;
}

.rc-slider-mark-text {
  color: theme(colors.gray.300) !important;
}

.rc-slider-step {
  bottom: -12px;
}
