@font-face {
  font-family: Galano Grotesque;
  src: url('./GalanoGrotesqueRegular.otf') format('opentype');
}

@font-face {
  font-family: Galano Grotesque;
  font-weight: 500;
  src: url('./GalanoGrotesqueMedium.otf') format('opentype');
}

@font-face {
  font-family: Galano Grotesque;
  font-weight: 600;
  src: url('./GalanoGrotesqueSemiBold.otf') format('opentype');
}

@font-face {
  font-family: Galano Grotesque;
  font-weight: 700;
  src: url('./GalanoGrotesqueBold.otf') format('opentype');
}
