/*Fade In Up*/
.fade-in-up-enter {
  opacity: 0;
  transform: translateY(50px);
}

.fade-in-up-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 150ms;
}

.fade-in-up-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-in-up-exit-active {
  opacity: 0;
  transform: translateY(50px);
  transition: all 150ms;
}

/*Fade In Down*/
.fade-in-down-enter {
  opacity: 0;
  transform: translateY(-50px);
}

.fade-in-down-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 300ms;
}

.fade-in-down-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-in-down-exit-active {
  opacity: 0;
  transform: translateY(-50px);
  transition: all 300ms;
}

/*Grow*/
.grow-enter {
  opacity: 0;
  transform: scale(0);
}

.grow-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 150ms;
}

.grow-exit {
  opacity: 1;
  transform: scale(1);
}

.grow-exit-active {
  opacity: 0;
  transform: scale(0);
  transition: all 150ms;
}

/* Slide Up */
.slide-up-enter {
  opacity: 0;
  transform: translateY(100%);
}

.slide-up-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 300ms;
}

.slide-up-exit {
  opacity: 1;
  transform: translateY(0);
}

.slide-up-exit-active {
  opacity: 0;
  transform: translateY(100%);
  transition: all 300ms;
}

/* Slide Down */
.slide-down-enter {
  opacity: 0;
  transform: translateY(-100%);
}

.slide-down-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 300ms;
}

.slide-down-exit {
  opacity: 1;
  transform: translateY(0);
}

.slide-down-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  transition: all 300ms;
}

/* Fade In Right */
.fade-in-right-enter {
  opacity: 0;
  transform: translateX(+50px);
}

.fade-in-right-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 500ms;
}

.fade-in-right-exit {
  opacity: 1;
  transform: translateX(0);
}

.fade-in-right-exit-active {
  opacity: 0;
  transform: translateX(+50px);
  transition: all 500ms;
}

/* Fade */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: all 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: all 300ms;
}
